<template>
  <div>
    <v-select
      v-model="data"
      :options="options"
      :reduce="(label) => label.code"
      :placeholder="placeholder"
      size="lg"
      :multiple="multiple"
    >
    </v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { http } from "@/services";

export default {
  name: "IntroducerUserSelect",
  props: {
    value: {
      type: Number,
      required: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Select user'
    },
    introducerId: {
      type: Number,
      required: false
    }
  },
  components: {
    vSelect
  },
  data () {
    return {
      options: []
    }
  },
  mounted () {
    this.fetch()
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value);
        this.$emit('selected', this.options.find(option => option.code === value))
      },
      get() {
        console.log('this.value', this.value)
        return this.value;
      }
    }
  },
  methods: {
    fetch () {
      http.get('introducer_users')
        .then((response) => {
          this.options = response.data.map(user => {
            return {
              code: user.id,
              label: user.name
            };
          }).sort((a,b)=>(a > b ? 1 : -1));
        })
        .catch((e) => {
          this.errors.push(e);
        });
    }
  }
};
</script>
