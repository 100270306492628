<template>
  <b-card v-if="transaction" class="mb-30">
    <div class="text-center">
      <h5 class="mt-2 pl-40 pr-40">{{ title }}</h5>
      <small>{{ transaction.status }}</small>
    </div>

    <template #footer>


      <p>
        <span class="font-weight-600">Clients:</span> {{ clientNameList({ clients: transaction.clients }) }}
      </p>

      <p>
        <span class="font-weight-600">Assigned to:</span>
        <span v-if="transaction.created_by">
          {{ transaction.created_by.name }}
        </span>
      </p>

      <p>
        <span class="font-weight-600">DM Advisor:</span>
        <span v-if="transaction.owner"> {{ transaction.owner.name }}
        </span>
      </p>

      <p>
        <span class="font-weight-600">Created Date:</span> {{ formatDateAndTimeAmPm(transaction.created_date) }}
      </p>

      <div v-if="showButtons" class="d-flex justify-content-between">
        <b-button
          @click="$emit('assign')"
          variant="primary">
          Re-assign Transaction
        </b-button>
      </div>
    </template>
  </b-card>
</template>

<script>

import { clientHelpers } from "@/mixins/clientHelpers";
import { dateFormat } from "@/mixins/dateFormat";

export default {
  name: "IntroducerTransactionCard",
  mixins: [clientHelpers, dateFormat],
  props: {
    transaction: {
      type: Object,
      required: false
    },
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    title() {
      if (this.transaction) {
        if (this.transaction.type === "mortgage") return "Mortgage";
        if (this.transaction.type === "insurance") return "Insurance";
        if (this.transaction.type === "estate_planning") return "Estate Planning";
        return this.transaction.type;
      }
     return 'NO TRANSACTION TYPE'
    },
    ownerType() {
      if (this.transaction.owner) {
        if (this.transaction.owner.is_staff) return "Staff";
        if (this.transaction.owner.is_introducer) return "Introducer";
        if (this.transaction.owner.is_client) return "Client";
      }
      return "NO OWNER TYPE";
    },
    createdByType() {
      if (this.transaction.created_by) {
        if (this.transaction.created_by.is_staff) return "Staff";
        if (this.transaction.created_by.is_introducer) return "Introducer";
        if (this.transaction.created_by.is_client) return "Client";
      }
      return "NO CREATED BY TYPE";
    },
    family() {
      if (this.transaction.clients) {
        let families = this.transaction.clients.map(client => client.family).map(family => family.name);
        return Array.from(new Set(families)).reduce((acc, name) => {
            if (acc) {
              return acc + ", " + name;
            } else {
              return name;
            }
          },
          "");
      }
      return "NO FAMILY";
    },
    introducer() {
      if (this.transaction.clients) {
        let introducers = this.transaction.clients
          .map(client => client?.family)
          .map(family => family?.source_new)
          .map(source => source?.introducer)
          .map(introducer => introducer?.introducer);

        return Array.from(new Set(introducers)).reduce((acc, name) => {
            if (acc) {
              return acc + ", " + name;
            } else {
              return name;
            }
          },
          "");

      }
      return "NO SOURCE";
    }
  }
};
</script>

<style scoped lang="scss">

</style>
