<template>
  <b-modal
    v-model="show"
    title="Assign Introducer to Transaction"
    @ok="assign"
    @cancel="done"
    size="md"
  >
    <b-row>
      <b-col>
        <h5>Transaction</h5>
        <IntroducerTransactionCard :transaction="transaction" :show-buttons="false" />
      </b-col>
    </b-row>

    <hr>
    <b-row>
      <b-col>

        <div class="mb-24">
          <label>Assign to:</label>
          <IntroducerUserSelect
            v-model="selected.user"
            />
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { http } from "@/services";
import { toast } from "@/mixins/toast";
import { dateFormat } from "@/mixins/dateFormat";
import { clientHelpers } from "@/mixins/clientHelpers";

import IntroducerTransactionCard from "@/views/introducer/transaction/all/cards/IntroducerTransactionCard";
import IntroducerUserSelect from "@/components/common/other/form/IntroducerUsersSelect";

export default {
  name: "TransactionIntroducerAssignModal",
  components: {IntroducerUserSelect, IntroducerTransactionCard },
  mixins: [toast, clientHelpers, dateFormat],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    transaction: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      selected: {
        user: null
      }
    };
  },
  computed: {
    show: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      }
    }
  },
  methods: {
    assign() {

      // guard
      if (!this.transaction) return this.toast("No transaction to assign", "Error", "danger");
      if (!this.selected.user) return this.toast("No user to assign to transaction", "Error", "danger");

      // patch
      let data = {
        id: this.transaction.id,
        created_by: this.selected.user
      };
      http.patch("transactions_assign_dismiss", data).then(
        () => {
          this.show = false;
          this.toast("Transaction assigned");
          this.$emit("assigned");
          this.done();
        }
      ).catch(
        error => {
          console.log(error);
          this.toast("Error occurred assigning transaction, please see console for details", "Error", "danger");
        }
      );
    },
    done() {
      this.$emit("done");
      this.selected.user = null;
    }
  }
};
</script>
