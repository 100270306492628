<template>
  <div class="main-content">

    <b-row>
      <b-col lg="8">
        <b-alert show dismissible>
          <h5>Note:</h5>
          <ul>
            <li>
              You can assign a transaction to a different advisor.
            </li>
          </ul>
        </b-alert>
      </b-col>
    </b-row>


    <b-row>
      <b-col class="text-right" lg="4" md="6">
        <div class="card bg-gray-100 user-filters">
          <div class="card-header p-10 pt-16">
            <b-input-group size="lg" class="mt-24">
              <b-input
                  v-model="filter.search"
                  @input="search"
                  placeholder="Search transactions"
                  rows="4"
                  size="lg" />
            </b-input-group>
            <p class="mt-12">Total Transaction Found: {{ totalRows }}</p>
          </div>
        </div>
      </b-col>

      <b-col lg="8" md="6" class="mt-30">
        <b-overlay
            :show="show.loading"
            rounded="sm"
            spinner-type="border"
            spinner-variant="primary"
        >
          <b-row>
            <b-col
                v-for="(transaction, index) in results"
                :key="index + 'transaction'"
                md="6"
                lg="4"
            >
              <IntroducerTransactionCard
                  :transaction="transaction"
                  @assign="assign(transaction)"
                  @dismiss="dismissConfirm(transaction)"
              />
            </b-col>
          </b-row>
        </b-overlay>

        <CardPagination
            :current-page="currentPage"
            :total-pages="totalPages"
            :results="results"
            :page-size="pageSize"
            :total-rows="totalRows"
            size="md"
            no-results-message="No assignable transactions found."
            @currentPageChange="currentPage=$event"
            @pageSizeChange="pageSize=$event"
            @fetch="fetch"
        />

      </b-col>

    </b-row>
    <TransactionIntroducerAssignModal
        v-model="show.assignModal"
        :transaction="selected.transaction"
        @done="clearAssign"
        @assigned="assigned"
    />
  </div>

</template>
<script>
import { http } from "@/services";
import { toast } from "@/mixins/toast";
import {debounce} from 'lodash';
import TransactionIntroducerAssignModal
  from "@/views/introducer/transaction/all/modals/TransactionIntroducerAssignModal";
import IntroducerTransactionCard from "@/views/introducer/transaction/all/cards/IntroducerTransactionCard";
import CardPagination from "@/components/common/other/CardPagination";


export default {
  name: "TransactionsManage",
  mixins: [toast],
  components: {
    CardPagination,
    IntroducerTransactionCard,
    TransactionIntroducerAssignModal

  },
  data() {
    return {
      results: [],
      currentPage: 1,
      totalPages: 0,
      totalRows: 0,
      pageSize: 9,
      show: {
        loading: true,
        assignModal: false
      },
      filter: {
        introducer: null,
        search: null
      },
      selected: {
        transaction: null,
        introducer: null
      }
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      let params = {
        page: this.currentPage,
        search: this.filter.search,
        page_size: this.pageSize
      };
      this.show.loading = true;
      http.get(`transactions_assignable`, { params: params })
          .then((response) => {
            this.results = response.data.results;
            this.currentPage = response.data.current_page_number;
            this.totalPages = response.data.total_pages;
            this.totalRows = response.data.total_objects;
            this.pageSize = response.data.page_size;
            this.show.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.show.loading = false;
          });
    },
    changePage(event) {
      // changes page
      this.currentPage = event;
      this.fetch();
    },
    changeIntroducer() {
      this.currentPage = 1;
      this.fetch();
    },
    assign(transaction) {
      this.show.assignModal = true;
      this.selected.transaction = transaction;
      console.log("assign transaction to introducer");
    },
    dismissConfirm(transaction) {
      let message = `Would you like to dismiss transaction '${transaction.id}', it will no longer be assignable to an introducer from here.`;
      this.$bvModal.msgBoxConfirm(message,
          {
            title: "Please confirm...",
            size: "sm",
            buttonSize: "sm",
            cancelVariant: "outline-primary",
            okVariant: "secondary",
            okTitle: "Dismiss",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            centered: true
          }).then((value) => {
        if (value) {
          this.dismiss(transaction);
        }
      }).catch((err) => {
        // An error occurred
        console.log(err);
      });
    },
    dismiss(transaction) {
      let data = {
        id: transaction.id,
        introducer_ignore_created_by: true
      };
      http.patch("introducer_transactions_assign_dismiss", data).then(
          () => {
            this.fetch();
            this.toast("Transaction dismissed");
          }
      ).catch(
          error => {
            console.log(error);
            this.toast("Error occurred dismissing transaction, please see console for details", "Error", "danger");
          }
      );
    },
    clearAssign() {
      this.selected.transaction = null;
    },
    assigned() {
      this.clearAssign();
      this.fetch();
    },
    search: debounce( function() {
      if (this.filter.search?.length > 3 || this.filter.search?.length === 0) {
        this.fetch()
      }
    }, 600)
  }
};
</script>
